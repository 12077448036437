import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as PaymentActions from '../../redux/actions/payments';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';


class Payments extends Component {

    componentDidMount() {
        this.props.dispatch(PaymentActions.getPaymentRecords(1));
    }

    render() {
        const { payments: { data, loading } } = this.props;
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5 col-12">
                            <div className="priceall">
                                <div className="col-md-12">
                                    <div className="row justify-content-between no-margin">
                                        <h3 className="text-centers px-md-5s"><b>View all Users</b></h3>
                                    </div>
                                </div>
                                <div className="row w-100 mx-0 mt-4">
                                    <div className="col-md-12 table-responsive">
                                        <table className="table table-striped" style={{width: '100%'}}>
                                            <thead className="thead-dark">
                                                <th>User ID</th>
                                                <th>Reference</th>
                                                <th>Payment Gateway</th>
                                                <th>Amount</th>
                                                <th>State</th>
                                                <th>Plan ID</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading
                                                        ? <td colSpan={7}><div className="col-md-12 text-center">
                                                            <Loading />
                                                        </div></td>
                                                        : data && data.length > 0 ? data.map(({ id, amount, user_id, payment_gateway, reference, plan_id, state }, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{user_id}</td>
                                                                    <td>{reference}</td>
                                                                    <td>{payment_gateway}</td>
                                                                    <td>{amount}</td>
                                                                    <td>{state}</td>
                                                                    <td>{plan_id}</td>
                                                                </tr>
                                                            )
                                                        })
                                                            : <td colSpan={7}>
                                                                <Empty
                                                                    title="Empty User List!"
                                                                    subText="Sorry, there are no users registered yet." />
                                                            </td> 
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
        payments: state.payments
    }))(Payments)
);