import React from 'react';
import moment from 'moment';
import { formatter } from '../../utils/formatter';

const GigItem = (props) => {
    const { gig } = props;
    const media = `https://backend-vendor-test.elfrique.com/public/${gig && gig.media}`
    return (
        <div className="col-12 grid-card p-0 mb-3">
            <div className="card cardresult shadow w-100">
                <div className="row h-100 no-gutters">
                    <div className="col-md-4 h-100">
                        <div className="card-imgs rounded-lefts h-100s">
                            <img alt="media" src={media} className="img-fluid bg-side" />
                        </div>
                    </div>
                    <div className="col-md-8 p-0">
                        <div className="card-body justify-content-between py-2 px-3 p-md-3 h-100">
                            <div className="row m-0 mb-2 mb-md-4 justify-content-between ">
                                <div className="col-12 col-md-6 pl-0 float-none float-md-left">
                                    <p className="card-text">
                                        <span className="postime ml-2">
                                            <small className="text-muted"><small><i className="mdi mdi-clock"></i></small> <b>Posted {moment(gig && gig.created_at).fromNow()}</b></small>
                                        </span>
                                    </p>
                                </div>
                                <div className="col-12 col-md-6 p-0 float-none float-md-right text-left text-md-right">
                                    <p>
                                        {
                                            gig && gig.tags ? gig.tags.split(',').map((tag, i) => {
                                                return <a className="badge sear badge-success">#{tag.trim()}</a>
                                            }) : null
                                        }
                                    </p>
                                </div>
                            </div>
                            <h5 className="card-title mb-2">{gig.name}</h5>

                            <div className="row m-0 mb-2 mb-md-4 flex-column justify-content-between">
                                <p className="card-text">
                                    <small className="text-muted">Buget range:</small>
                                </p>
                                <h6 className="card-text">{gig && formatter.format(gig.budget_start)} - {gig && formatter.format(gig.budget_end)}</h6>
                            </div>
                            {
                                props.isOwner
                                    ? <div className="action-center">
                                        <a href={`/gig/view/${gig && gig.id}`}><button className="action">View details</button></a>
                                        <div className="float-right">
                                            <a href={`/gig/edit/${gig && gig.id}`}><button className="action">Edit</button></a>
                                            <button className="action action-delete" data-toggle="modal" data-target="#editGigModal" onClick={props.selectService.bind(this, props.gig)}>Delete</button>
                                        </div>
                                    </div>
                                    : <div className="action-center">
                                        <a href={`/service/view/${gig && gig.id}`}><button className="action">View details</button></a>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GigItem;