import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormValidator from "./../../utils/FormValidator";
import * as SubscriptionActions from '../../redux/actions/subscriptions';
import { PulseLoader } from 'react-spinners';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class Subscriptions extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "name", method: "isEmpty", validWhen: false, message: "Name cannot be empty." },
            { field: "price", method: "isEmpty", validWhen: false, message: "Price cannot be empty." },
            { field: "price", method: "isInt", validWhen: true, message: "Price must be a number." },
            { field: "description", method: "isEmpty", validWhen: false, message: "Description cannot be empty." }
        ]);

        this.state = {
            plan: {
                name: "",
                price: "",
                description: ""
            },
            validation: this.validator.reset(),
            selectedPlan: null
        }
    }

    componentDidMount() {
        this.props.dispatch(SubscriptionActions.fetchAllSubscriptions());
    }

    handleChange = (propertyName, e) => {
        const plan = this.state.plan;
        plan[propertyName] = e.target.value;
        this.setState({ plan });
    }

    handleUpdateChange = (propertyName, e) => {
        const selectedPlan = this.state.selectedPlan;
        selectedPlan[propertyName] = e.target.value;
        this.setState({ selectedPlan });
    }

    handleSelection = (plan) => {
        this.setState({selectedPlan: plan})
    }

    addSubscription = (e) => {
        e.preventDefault();
        const { name, price, description } = this.state.plan;
        const payload = { name, price, description };

        const formData = new FormData();
        formData.append('name', name);
        formData.append('amount', price);
        formData.append('description', description);
        formData.append('number_of_days', 30);
        formData.append('interval', 'every 30 days');


        const validation = this.validator.validate(payload);

        if (validation.isValid) {
            this.props.dispatch(SubscriptionActions.createSubscription(formData));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    updateSubscription = (e) => {
        e.preventDefault();
        const { id, name, amount, description } = this.state.selectedPlan;
        const payload = { name, price: amount, description };

        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('amount', amount);
        formData.append('description', description);
        formData.append('number_of_days', 30);
        formData.append('interval', 'every 30 days');

        const validation = this.validator.validate(payload);

        if (validation.isValid) {
            this.props.dispatch(SubscriptionActions.updateSubscription(formData));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    deleteSubscription = (id) => {
        this.props.dispatch(SubscriptionActions.deleteSubscription(id));
    }

    _deleteSubscription = (id, e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>You want to delete this file?</p>
                    <button className="btn-close" onClick={onClose}>No</button>
                    <button
                      onClick={() => {
                        this.deleteSubscription(id);
                        onClose();
                      }}
                    >
                      Yes, Delete it!
                    </button>
                  </div>
                );
            }
          });
    }

    render() {
        const { selectedPlan, validation } = this.state;
        const { subscriptions: {subscriptions, subscriptionsLoading, createLoading, updateLoading, deleteLoading} } = this.props;
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5 col-12">
                            <div className="col-md-12 priceall">
                                <div className="row no-margin">
                                    <div className="col-md-12">
                                        <div className="row justify-content-between no-margin">
                                            <h3 className="text-centers px-md-5s"><b>Manage Subscription Plans</b></h3>
                                            <button className="btn-subscription" data-toggle="modal" data-target="#addSubscriptionPlan">Add Subscription Plan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row w-100s mx-0 mt-4">
                                    {
                                        subscriptionsLoading
                                        ? <div className="col-md-12 text-center">
                                            <Loading />
                                        </div>
                                        : subscriptions && subscriptions.length > 0 ? subscriptions.map((plan, i) => {
                                            const { name, description, amount } = plan;
                                            return (
                                                <div className="text-center col-md-4">
                                                    <div className="pricesbx">
                                                        <h5 className="w-100 p-3"> {name} - NGN {amount}</h5>
                                                        <div className="py-4 px-3">
                                                            {
                                                                description.split(',').map((item, i) => {
                                                                    return <p>{item}</p>
                                                                })
                                                            }
                                                            <a href="#" className="button mr-2" data-toggle="modal" data-target="#editSubscriptionPlan" onClick={this.handleSelection.bind(this, plan)}>Update Plan</a>
                                                            <a href="#" className="button delete" onClick={this._deleteSubscription.bind(this, plan.id)}>Delete Plan</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : <Empty
                                        title="Empty Subscription Plans!" 
                                        subText="You have not created any plans yet" />
                                    }
                                </div>
                                <div className="modal fade" id="addSubscriptionPlan" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalCenterTitle">Add Subscription Plan</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body text-center">
                                                <form>
                                                    <div className="form-group text-left">
                                                        <label>Subscription Plan Name</label>
                                                        <input type="text" className="form-control" onChange={this.handleChange.bind(this, "name")} />
                                                        { validation.name.isValid ? '' : <span className="error-message text-left">{validation.name.message}</span> }
                                                    </div>
                                                    <div className="form-group text-left">
                                                        <label>Subscription Plan Price</label>
                                                        <input type="text" className="form-control" onChange={this.handleChange.bind(this, "price")} />
                                                        { validation.price.isValid ? '' : <span className="error-message text-left">{validation.price.message}</span> }
                                                    </div>
                                                    <div className="form-group text-left">
                                                        <label>Subscription Plan Description</label>
                                                        <textarea className="form-control" onChange={this.handleChange.bind(this, "description")} />
                                                        { validation.description.isValid ? '' : <span className="error-message text-left">{validation.description.message}</span> }
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer text-center">
                                                <button type="button" className="btn btn-secondary btn-cancel" data-dismiss="modal">Cancel</button>
                                                {
                                                    createLoading
                                                    ? <button type="button" className="btn btn-primary btn-success">Adding...</button>
                                                    : <button type="button" className="btn btn-primary btn-success" onClick={this.addSubscription}>Add Plan</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="editSubscriptionPlan" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalCenterTitle">Edit Subscription Plan</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body text-center">
                                                <form>
                                                    <div className="form-group text-left">
                                                        <label>Subscription Plan Name</label>
                                                        <input type="text" className="form-control"
                                                        value={selectedPlan && selectedPlan.name} onChange={this.handleUpdateChange.bind(this, "name")} />
                                                        { validation.name.isValid ? '' : <span className="error-message text-left">{validation.name.message}</span> }
                                                    </div>
                                                    <div className="form-group text-left">
                                                        <label>Subscription Plan Price</label>
                                                        <input type="text" className="form-control"
                                                        value={selectedPlan && selectedPlan.amount} onChange={this.handleUpdateChange.bind(this, "price")} />
                                                        { validation.price.isValid ? '' : <span className="error-message text-left">{validation.price.message}</span> }
                                                    </div>
                                                    <div className="form-group text-left">
                                                        <label>Subscription Plan Description</label>
                                                        <textarea className="form-control"
                                                        value={selectedPlan && selectedPlan.description} onChange={this.handleUpdateChange.bind(this, "description")} />
                                                        { validation.description.isValid ? '' : <span className="error-message text-left">{validation.description.message}</span> }
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer text-center">
                                                <button type="button" className="btn btn-secondary btn-cancel" data-dismiss="modal">Cancel</button>
                                                {
                                                    updateLoading
                                                    ? <button type="button" className="btn btn-primary btn-success">Updating...</button>
                                                    : <button type="button" className="btn btn-primary btn-success" onClick={this.updateSubscription}>Update Plan</button>   
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>

                    </div>

                </div>

            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
      subscriptions: state.subscriptions
    }))(Subscriptions)
);