const initialState = {
    gig: null,
    gigs: [],
    error: null,
    loading: false,
    gigLoading: false,
    gigsLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_GIG_PENDING':
            return { ...state, loading: action.loading };
        case 'CREATE_GIG_SUCCESS':
            return { ...state, loading: action.loading };
        case 'CREATE_GIG_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'UPDATE_GIG_PENDING':
            return { ...state, loading: action.loading };
        case 'UPDATE_GIG_SUCCESS':
            return { ...state, loading: action.loading };
        case 'UPDATE_GIG_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'FETCH_MY_GIGS_PENDING':
            return { ...state, gigsLoading: action.loading };
        case 'FETCH_MY_GIGS_SUCCESS':
            return { ...state, gigs: action.gigs, gigsLoading: action.loading };
        case 'FETCH_MY_GIGS_FAILED':
            return { ...state, gigsLoading: action.loading, error: action.error };

        case 'FETCH_ALL_GIGS_PENDING':
            return { ...state, gigsLoading: action.loading };
        case 'FETCH_ALL_GIGS_SUCCESS':
            return { ...state, gigs: action.gigs, gigsLoading: action.loading };
        case 'FETCH_ALL_GIGS_FAILED':
            return { ...state, gigsLoading: action.loading, error: action.error };

        case 'FETCH_SINGLE_GIG_PENDING':
            return { ...state, gigLoading: action.loading };
        case 'FETCH_SINGLE_GIG_SUCCESS':
            return { ...state, gig: action.gig, gigLoading: action.loading };
        case 'FETCH_SINGLE_GIG_FAILED':
            return { ...state, gigLoading: action.loading, error: action.error };

        case 'DELETE_GIG_PENDING':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_GIG_SUCCESS':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_GIG_FAILED':
            return { ...state, deleteLoading: action.loading, error: action.error };

        default:
            return state;
    }
};