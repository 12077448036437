import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import FormValidator from "./../../utils/FormValidator";
import * as UserActions from '../../redux/actions/users';


class Login extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "email", method: "isEmail", validWhen: true, message: "This must be a valid email." },
            { field: "password", method: "isEmpty", validWhen: false, message: "Password cannot be empty." }
        ]);

        this.state = {
            user: {
                email: "",
                password: ""
            },
            validation: this.validator.reset()
        }
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const user = this.state.user;
        user[propertyName] = e.target.value;
        this.setState({ user })
    }

    login = (e) => {
        e.preventDefault();
        const { email, password } = this.state.user;
        const payload = { email, password };

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password)

        const validation = this.validator.validate(payload);

        if (validation.isValid) {
            this.props.dispatch(UserActions.login(formData));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    render() {
        const { validation } = this.state;
        const { error, loading } = this.props.users;
        return (
            <article style={{ background: '#ffa010', backgroundImages: 'url(../img/loginbackground.png)', minHeight: '100vh' }} className="signup-login w-100s h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0 justify-content-center align-items-center" style={{height: '100vh'}}>
                        <section className="main-body col-md-6">
                            <div className="m-5s">
                                <a href="/" className="navbar-brand pr-4 mb-5 d-block text-center" rel="home" itemprop="url">
                                    <img width="98" height="42" src="img/elfrique-logo-white.png" className="custom-logo" alt="Elfrique" itemprop="logo" />
                                </a>
                                <div className="the-back p-2s pt-4" style={{paddingBottom: '50px'}}>
                                    <div className="userall">
                                        <h4 className="graytxt text-center pb-3 border-bottom">Welcome Back!</h4>
                                        <div className="container-fluid">
                                            <div className="row m-0 justify-content-center text-center">
                                                <div className="col-12 p-0 login-wrap">
                                                    <div className="login-form">
                                                        <form className="">
                                                            <div className="form-row">
                                                                <div className="form-group col-12 text-left">
                                                                    <label>Email Address &nbsp;</label>
                                                                    { validation.email.isValid ? '' : <span className="error-message text-left">{validation.email.message}</span> }
                                                                    <input type="email" onChange={this.handleChange.bind(this, "email")} className="form-control shadows" id="loginControlEmail" placeholder="Email Address" />
                                                                </div>
                                                                <div className="form-group col-12 text-left">
                                                                    <label>Password &nbsp;</label>
                                                                    { validation.password.isValid ? '' : <span className="error-message text-left">{validation.password.message}</span> }
                                                                    <input type="password" onChange={this.handleChange.bind(this, "password")} className="form-control shadows" id="loginControlPassword" placeholder="Password" />
                                                                </div>
                                                            </div>
                                                            <button className="btn w-100 shadows" onClick={this.login}>{ loading ? 'loading...' : 'Login' }</button>
                                                        </form>

                                                        <p className="text-left mt-4">Don't have an account yet?  <Link to="/signup/vendor">Signup as Vendor</Link> or <Link to="/signup/customer">Signup as Customer</Link></p>

                                                        <p className="text-left mt-4">Forgot password?  <Link to="/forgot/password">Click here</Link></p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
      users: state.users
    }))(Login)
);