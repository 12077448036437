import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import * as SubscriptionActions from '../../redux/actions/subscriptions';
import * as PaymentActions from '../../redux/actions/payments';
import * as UserActions from '../../redux/actions/users';

class Pricing extends Component {

    state = {
        plans: [
            {
                name: "Free",
                amount: 0,
                description: "1 Enquiries Guaranteed, Google Page SEO enhanced"
            },
            {
                name: "Basic Plan",
                amount: 15600,
                description: "5 Enquiries Guaranteed, Google Page SEO enhanced, Bidding, Contact Display"
            },
            {
                name: "Exclusive Plan",
                amount: 29800,
                description: "12 Enquiries Guaranteed, Google Page SEO enhanced, Bidding, Contact Display, Elfrique Home Page Display, Elfrique Slider Display"
            }
        ]
    }

    componentDidMount() {
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
        this.props.dispatch(SubscriptionActions.fetchAllSubscriptions());
        if (profile && profile.id) {
            this.props.dispatch(UserActions.getProfile())
            this.props.dispatch(PaymentActions.getAutoRenewedPlansUser(profile && profile.id))
        }
    }

    initiatePaymentModal = (email, amount, phone, reference, raveId) => {
        const API_publicKey = "FLWPUBK-0583032f9a74f6cce409c9f685f15167-X";
        var x = window.getpaidSetup({
            PBFPubKey: API_publicKey,
            customer_email: email,
            amount: amount,
            customer_phone: phone,
            currency: "NGN",
            payment_method: "both",
            txref: reference,
            payment_plan: raveId,
            onclose: () => { },
            callback: async(response) => {
                var txref = response.data.txRef; // collect flwRef returned and pass to a server page to complete status check.
                console.log("This is the response returned after a charge", response);
                if (response.respcode == "00" || response.respcode == "0") {
                    // redirect to a success page
                    const payload = new FormData();
                    payload.append('reference', reference);
                    payload.append('payment_gateway', 'flutterwave');
                    await this.props.dispatch(PaymentActions.confirmPayment(payload))
                    await this.props.dispatch(UserActions.getProfile()).then(() => {
                        window.location.reload();
                    })
                } else {
                    // redirect to a failure page.
                }
                x.close(); // use this to close the modal immediately after payment.
            }
        });
    }

    handleSubscription = async (planId) => {
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
        if (!profile) return alert('Please log in first');
        const { email, phone } = profile;

        const payload = new FormData();
        payload.append('plan_id', planId);
        payload.append('payment_gateway', "flutterwave");
        await this.props.dispatch(PaymentActions.initiatePlanActivation(payload)).then(async() => {
            try {
                const { planActivationError } = this.props.payments;
                if (!planActivationError) {
                    const dataResponse = this.props.payments.data;
                    const { raveplan: { plan_id, amount, rave_id } } = dataResponse;
                    const { data: { reference, isocode, converted_amount } } = dataResponse;
        
                    const _payload2 = { plan_id, amount, converted_amount: converted_amount.amount, reference, payment_gateway: "flutterwave", payment_method: "web", isocode };
                    const payload2 = new FormData();
                    Object.keys(_payload2).map((key, i) => {
                        payload2.append(key, _payload2[key]);
                    })

                    await this.props.dispatch(PaymentActions.payForPlan(payload2)).then(() => {
                        const { planPaymentError } = this.props.payments;
                        if (!planPaymentError) {
                            this.initiatePaymentModal(email, amount, phone, reference, rave_id);
                        }
                    })
                }
            } catch (error) {
                console.log(error)
            }
        })
    }

    handleCancelAutoRenewal = () => {
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
        const planId = profile && profile.plan_id;
        const userId = profile && profile.id;
        const { payments: { subscriptionData } } = this.props;
        const ravePlanId = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        this.props.dispatch(PaymentActions._cancelSubscription(ravePlanId))
    }

    render() {
        const { subscriptions: { subscriptions, subscriptionsLoading }, payments: { cancelLoading, subscriptionData } } = this.props;
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
        const subscriptionId = profile && profile.plan_id;
        const isLoggedIn = profile ? true : false;
        const ravePlanStatus = subscriptionData && subscriptionData[0] && subscriptionData[0].status;
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5 col-12">
                            <div className="priceall">
                                <div className="text-right mb-4">
                                    {
                                        subscriptionId ? ravePlanStatus && ravePlanStatus !== "cancelled" && <button onClick={this.handleCancelAutoRenewal}>{ cancelLoading ? 'Canceling..' : 'Cancel autorenewal' }</button>
                                        || <button className="btn-disabled">Plan Autorenewal has been cancelled</button> : null
                                    }
                                </div>
                                <h3 className="text-center px-md-5"><b>Free sounds great but why not get more value from our plans</b></h3>
                                <div className="row w-100s mx-0 mt-4">
                                    {
                                        subscriptionsLoading
                                            ? <div className="col-md-12 text-center">
                                                <Loading />
                                            </div>
                                            : subscriptions && subscriptions.length > 0 ? subscriptions.map((plan, i) => {
                                                const { name, description, amount, id } = plan;
                                                return (
                                                    <div className="pricesbx text-center col-12 col-lg-4">
                                                        <h5 className="w-100 p-3 rounded"> {name} - NGN {amount}</h5>
                                                        <div className="py-4 px-3 rounded shadow">
                                                            {
                                                                description.split(',').map((item, i) => {
                                                                    return <p className="mb-3">{item}</p>
                                                                })
                                                            }
                                                            {
                                                                subscriptionId
                                                                ? subscriptionId === id
                                                                    ? <p className="buttons delete mt-3" style={{ borderRadius: '6px', padding: '10px', background: 'green', color: '#fff'}}>Activated</p>
                                                                    : <p className="buttons delete mt-3" style={{ borderRadius: '6px', padding: '10px', background: '#f2f2f2', color: '#a3a3a3'}}>Inactive</p>
                                                                : isLoggedIn 
                                                                    ? <button className="button delete" onClick={this.handleSubscription.bind(this, id)}>Activate Plan</button>
                                                                    : <button className="button delete" onClick={() => window.open('/login', '_self')}>Sign up</button>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }) : <Empty
                                                    title="Empty Subscription Plans!"
                                                    subText="Sorry, no subscription plans created yet" />
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
        payments: state.payments,
        subscriptions: state.subscriptions
    }))(Pricing)
);