import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as GigActions from '../../redux/actions/gigs';
import GigItem from '../../components/GigItem';

class Gigs extends Component {

    componentDidMount() {
        this.props.dispatch(GigActions.fetchAllGigs());
    }

    render() {
        const { gigs: { gigs, gigsLoading } } = this.props;
        return (
            <main>
                <section className="banner-result position-relative align-middle">
                    <div className="position-absolute w-100 h-100">
                        <div className="container p-md-5">
                            <div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
                                <div className="banner-searchbox results row m-0 align-items-center justify-content-center rounded">
                                    <form action="" className="form-inline align-items-center justify-content-center w-100 mx-auto px-3 rounded w-100">
                                        <div className="form-group form-row text-xs-center justify-content-xs-center w-100">
                                            <div className="col-12 col-md-4 p-1">
                                              <input type="text" name="searchkeys" placeholder="Enter Keyword or Phrase" className="border border-success searchword form-control" />

                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <input type="text" name="searchlocat" placeholder="Search Location" className="border border-success form-control rounded-0" />
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <select className="form-control border border-success rounded-0">
                                                    <option>Catering & Hospitality</option>
                                                    <option>Information Technology</option>
                                                    <option>Real Estate</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <select className="form-control border border-success rounded-0">
                                                    <option>Availability Speed</option>
                                                    <option>Availability Speed</option>
                                                    <option>Availability Speed</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <select className="form-control border border-success rounded-0">
                                                    <option>Cost Range</option>
                                                    <option>Cost Range</option>
                                                    <option>Cost Range</option>
                                                </select>
                                            </div>
                                            <div className="col-8 col-md-4 p-1 h-100 submt">
                                                <a href="#" className="small text-muted"><i></i>Save search as Favourite</a>
                                                <button type="submit" className="button rounded-circle" ><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <article className="top-background result w-100 h-100">
                    <div className="container py-3 p-md-3">
                        <div className="row">
                            <section className="main-body p-0 col-12">
                                <div className="row col-12 grid-label justify-content-center p-0 mx-0 my-3 text-center">
                                </div>
                                <div className="banner-form col-12 col-lg-3 float-none float-lg-left pt-4">
                                    <div className="top-form bg-light py-4 px-3 list-group list-group-flush mb-3 rounded shadow">
                                        <a href="#" className="list-group-item list-group-item-action small">Emcees & Performances</a>
                                        <a href="#" className="list-group-item list-group-item-action small">Catering & Hospitality</a>
                                        <a href="#" className="list-group-item list-group-item-action small">Event Halls & Venues</a>
                                        <a href="#" className="list-group-item list-group-item-action small">Decoration</a>
                                        <a href="#" className="list-group-item list-group-item-action small">Costume & Fashion Design</a>
                                        <a href="#" className="list-group-item list-group-item-action small">Event Planning & Management</a>
                                        <a href="#" className="list-group-item list-group-item-action small">Stage Electrical & Fabrication</a>
                                    </div>
                                    <div className="">
                                        <div id="adslide" className="carousel slide" data-ride="carousel">
                                            <ol className="carousel-indicators">
                                                <li data-target="#adslide" data-slide-to="0" className="active"></li>
                                                <li data-target="#adslide" data-slide-to="1"></li>
                                                <li data-target="#adslide" data-slide-to="2"></li>
                                            </ol>
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <a href="#" target="_blank">
                                                        <img src="img/adslide1.png" className="d-block w-100" alt="ad-banner1" />
                                                    </a>
                                                </div>
                                                <div className="carousel-item">
                                                    <a href="#" target="_blank">
                                                        <img src="img/adslide1.png" className="d-block w-100" alt="ad-banner1" />
                                                    </a>
                                                </div>
                                                <div className="carousel-item">
                                                    <a href="#" target="_blank">
                                                        <img src="img/adslide1.png" className="d-block w-100" alt="ad-banner1" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-after pt-4 col-12 col-lg-9 float-none float-lg-right">
                                    <div className="row m-0 banner-txt justify-content-between h-100 col-12">
                                        <div className="row w-100 justify-content-between py-3 my-3 mx-0 border-bottom">
                                            <div className="grid-title">
                                                <h5 className="text-center text-md-left">All Jobs {!gigsLoading && gigs && `(${gigs.length})`}</h5>
                                            </div>
                                            <p className="small">Sort by: <a href="#" className="orangetxt small mx-2">Most recent</a> <a href="#" className="text-muted small mx-2">Price: Low to High</a> <a href="#" className="text-muted small mx-2">Price: High to Low</a></p>
                                        </div>
                                        <div id="cardgrid" className="grid slide">
                                            <div className="">
                                                <div className="grid-item">
                                                    <div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0">
                                                        {
                                                            gigsLoading 
                                                            ? 'Loading jobs...'
                                                            : gigs && gigs.map((gig, i) => {
                                                                return (<GigItem gig={gig} key={i} />)
                                                            }) || "No jobs yet"
                                                        }
                                                        <div className="col-12 my-3 text-center">
                                                            {/* <a href="#" className="btn button2">View details</a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </article>
            </main>
        )
    }
}

export default withRouter(
    connect(state => ({
      gigs: state.gigs
    }))(Gigs)
);
