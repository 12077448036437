import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as UserActions from '../../redux/actions/users';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';
import * as PaymentActions from '../../redux/actions/payments';


class Users extends Component {

    componentDidMount() {
        this.props.dispatch(UserActions.fetchAllUsers());
    }

    reactivatePlan = () => {
        const { payments: { subscriptionData } } = this.props;
        const ravePlanId = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        this.props.dispatch(PaymentActions._reactivateSubscription(ravePlanId))
    }

    cancelPlan = () => {
        const { payments: { subscriptionData } } = this.props;
        const ravePlanId = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        this.props.dispatch(PaymentActions._cancelSubscription(ravePlanId))
    }

    getUserSubscriptions = (id) => {
        this.props.dispatch(PaymentActions.getAutoRenewedPlansUser(id));
    }

    render() {
        const { users: { users, usersLoading }, payments: { loading, subscriptionData } } = this.props;
        const plan_id = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        const plan_status = subscriptionData && subscriptionData[0] && subscriptionData[0].status;
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5 col-12">
                            <div className="priceall">
                                <div className="col-md-12">
                                    <div className="row justify-content-between no-margin">
                                        <h3 className="text-centers px-md-5s"><b>View all Users</b></h3>
                                    </div>
                                </div>
                                <div className="row w-100 mx-0 mt-4">
                                    <div className="col-md-12 table-responsive">
                                        <table className="table table-striped" style={{ width: '100%' }}>
                                            <thead className="thead-dark">
                                                <th>User ID</th>
                                                <th>Business Name</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>User Type</th>
                                                <th>Email Address</th>
                                                <th>Plan</th>
                                                <th>Action</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    usersLoading
                                                        ? <td colSpan={7}><div className="col-md-12 text-center">
                                                            <Loading />
                                                        </div></td>
                                                        : users && users.length > 0 ? users.map(({ id, name, firstname, lastname, user_type, email, plan_name, plan_id }, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{id}</td>
                                                                    <td>{name}</td>
                                                                    <td>{firstname}</td>
                                                                    <td>{lastname}</td>
                                                                    <td>{user_type}</td>
                                                                    <td>{email}</td>
                                                                    <td>{plan_name || 'N/A'}</td>
                                                                    <td>
                                                                        {
                                                                            plan_id
                                                                                ? <>
                                                                                    <button type="button" onClick={this.getUserSubscriptions.bind(this, id)} class="btn btn-primary" style={{ lineHeight: '15px' }} data-toggle="modal" data-target="#exampleModal">Manage Plans</button>
                                                                                </>
                                                                                : 'No action'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                            : <td colSpan={7}>
                                                                <Empty
                                                                    title="Empty User List!"
                                                                    subText="Sorry, there are no users registered yet." />
                                                            </td>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Manage Plans</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Status: {plan_status}</p>
                                         {
                                            !loading && subscriptionData
                                            ? <> 
                                                <button className="btn-action mr-2 mb-2" style={{ lineHeight: '15px' }} onClick={this.reactivatePlan.bind(this, plan_id)}>Reactivate Plan</button>
                                                {
                                                    plan_status !== "cancelled"
                                                    ? <button className="btn-action cancel" style={{ lineHeight: '15px' }} onClick={this.cancelPlan.bind(this, plan_id)}>Cancel Plan</button>
                                                    : null
                                                }
                                            </>
                                            : loading && !subscriptionData ? 'loading...' : 'loading..'
                                         }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article >
        )
    }
}

export default withRouter(
    connect(state => ({
        users: state.users,
        payments: state.payments
    }))(Users)
);