import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};
const configAuth = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
    }
};


export const login = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'LOGIN_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/login`, payload, config)
            .then((response) => {
                const accessToken = response.data.token;
                window.localStorage.setItem('accessToken', accessToken);
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    loading: false,
                })
                return response;
            })
            .then(async() => {
                await Swal.fire(
                    'Login Successful',
                    'You have successfully logged into your account',
                    'success'
                ).then(() => {
                    window.location.replace('/dashboard')
                })
            })
            .catch((error) => {
                console.log(error.response)
                Swal.fire(
                    'Login Attempt',
                    error.response && error.response && error.response.data && error.response.data.error,
                    'error'
                )
                dispatch({
                    type: 'LOGIN_FAILED',
                    loading: false,
                    error: error.response && error.response && error.response.data && error.response.data.error
                })
            });
    };
};

export const register = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'REGISTER_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/register`, payload, config)
            .then((response) => {
                const accessToken = response.data.token;
                window.localStorage.setItem('accessToken', accessToken);
                dispatch({
                    type: 'REGISTER_SUCCESS',
                    loading: false,
                })
            })
            .then(async() => {
                await Swal.fire(
                    'Registration Successful',
                    'You have successfully created your account',
                    'success'
                ).then(() => {
                    window.location.replace('/dashboard')
                })
            })
            .catch((error) => {
                Swal.fire(
                    'Registration Attempt',
                    error.response && error.response.data && error.response.data.error,
                    'error'
                )
                dispatch({
                    type: 'REGISTER_FAILED',
                    loading: false,
                    error: error.response && error.response.data.data && error.response.data.error,
                })
            });
    };
};

export const forgotPassword = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'FORGOT_PASSWORD_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/forgot-password`, payload, config)
            .then((response) => {
                dispatch({
                    type: 'FORGOT_PASSWORD_SUCCESS',
                    loading: false,
                })
                return response;
            })
            .then(async() => {
                await Swal.fire(
                    'Reset Password Token Sent Successfully',
                    'Please check your email for the next set of instructions',
                    'success'
                ).then(() => {
                    window.location.replace('/reset/password')
                })
            })
            .catch((error) => {
                console.log(error.response)
                Swal.fire(
                    'Reset Password Attempt Failed',
                    error.response && error.response && error.response.data && error.response.data.error,
                    'error'
                )
                dispatch({
                    type: 'FORGOT_PASSWORD_FAILED',
                    loading: false,
                    error: error.response && error.response && error.response.data && error.response.data.error
                })
            });
    };
};

export const resetPassword = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'RESET_PASSWORD_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/reset-password`, payload, config)
            .then((response) => {
                const status = response.data.status;
                const message = response.data.message;
                dispatch({
                    type: 'RESET_PASSWORD_SUCCESS',
                    loading: false,
                })
                if (status === 0) {
                    return Swal.fire(
                        'Reset Password Attempt Failed',
                        message,
                        'error'
                    )
                } else {
                    Swal.fire(
                        'Password Reset Successfully',
                        'Please proceed to login with your new password',
                        'success'
                    ).then(() => {
                        window.location.replace('/login')
                    })
                }
                return response;
            })
            .catch((error) => {
                console.log(error.response)
                Swal.fire(
                    'Reset Password Attempt Failed',
                    error.response && error.response && error.response.data && error.response.data.error,
                    'error'
                )
                dispatch({
                    type: 'RESET_PASSWORD_FAILED',
                    loading: false,
                    error: error.response && error.response && error.response.data && error.response.data.error
                })
            });
    };
};

export const getProfile = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_PERSONAL_PROFILE_PENDING',
            loading: true,
            user: null,
            error: null
        })
        return axios.get(`${base_url}/getuser`, configAuth)
            .then((response) => {
                const user = response.data.data;
                window.localStorage.setItem("_profile", JSON.stringify(user));
                dispatch({
                    type: 'FETCH_PERSONAL_PROFILE_SUCCESS',
                    loading: false,
                    user,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_PERSONAL_PROFILE_FAILED',
                    loading: false,
                    user: null,
                    error: error.response
                })
            });
    };
};

export const fetchAllUsers = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_USERS_PENDING',
            loading: true,
            users: null,
            error: null
        })
        return axios.get(`${base_url}/get-users`, configAuth)
            .then((response) => {
                const users = response.data.data;
                dispatch({
                    type: 'FETCH_ALL_USERS_SUCCESS',
                    loading: false,
                    users,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_USERS_FAILED',
                    loading: false,
                    user: null,
                    error: error.response
                })
            });
    };
};

export const getBusinessProfile = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_BUSINESS_PROFILE_PENDING',
            loading: true,
            business: null,
            error: null
        })
        return axios.get(`${base_url}/get-business-detail`, configAuth)
            .then((response) => {
                const business = response.data.data;
                dispatch({
                    type: 'FETCH_BUSINESS_PROFILE_SUCCESS',
                    loading: false,
                    business,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_BUSINESS_PROFILE_FAILED',
                    loading: false,
                    business: null,
                    error: error.response
                })
            });
    };
};

export const updatePersonalProfile = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_PERSONAL_PROFILE_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/update-user`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'UPDATE_PERSONAL_PROFILE_SUCCESS',
                    loading: false,
                    error: null
                })
            })
            .then(async() => {
                await Swal.fire(
                    'Update Personal Profile Successful',
                    'You have successfully updated your personal profile',
                    'success'
                )
                window.location.reload();
            })
            .catch((error) => {
                Swal.fire(
                    'Update Personal Profile Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
                dispatch({
                    type: 'UPDATE_PERSONAL_PROFILE_FAILED',
                    loading: false,
                    user: null,
                    error: error.response
                })
            });
    };
};

export const updateBusinessProfile = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_BUSINESS_PROFILE_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/update-user`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'UPDATE_BUSINESS_PROFILE_SUCCESS',
                    loading: false,
                    error: null
                })
            })
            .then(async() => {
                await Swal.fire(
                    'Update Business Profile Successful',
                    'You have successfully updated your business profile',
                    'success'
                )
                window.location.reload();
            })
            .catch((error) => {
                Swal.fire(
                    'Update Business Profile Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
                dispatch({
                    type: 'UPDATE_BUSINESS_PROFILE_FAILED',
                    loading: false,
                    user: null,
                    error: error.response
                })
            });
    };
};

export const addNewsletterSubscriber = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'ADD_NEWSLETTER_SUBSCRIBER_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/add-subscriber`, payload, config)
            .then((response) => {
                const accessToken = response.data.token;
                dispatch({
                    type: 'ADD_NEWSLETTER_SUBSCRIBER_SUCCESS',
                    loading: false,
                })
                return response;
            })
            .then(async() => {
                await Swal.fire(
                    'Newsletter Subscription Successful',
                    'You have successfully subscribed to getting our newsletters',
                    'success'
                )
            })
            .catch((error) => {
                console.log(error.response)
                Swal.fire(
                    'Newsletter Subscription Failed',
                    'Subscription failed, please try again!',
                    'error'
                )
                dispatch({
                    type: 'ADD_NEWSLETTER_SUBSCRIBER_FAILED',
                    loading: false,
                    error: error.response && error.response && error.response.data && error.response.data.error
                })
            });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('_profile');
        dispatch({
            type: 'LOGOUT_USER',
            user: null
        })
        window.location.replace('/')
    };
};
