const initialState = {
    service: null,
    services: [],
    error: null,
    loading: false,
    serviceLoading: false,
    servicesLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_SERVICE_PENDING':
            return { ...state, loading: action.loading };
        case 'CREATE_SERVICE_SUCCESS':
            return { ...state, loading: action.loading };
        case 'CREATE_SERVICE_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'UPDATE_SERVICE_PENDING':
            return { ...state, loading: action.loading };
        case 'UPDATE_SERVICE_SUCCESS':
            return { ...state, loading: action.loading };
        case 'UPDATE_SERVICE_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'FETCH_MY_SERVICES_PENDING':
            return { ...state, servicesLoading: action.loading };
        case 'FETCH_MY_SERVICES_SUCCESS':
            return { ...state, services: action.services, servicesLoading: action.loading };
        case 'FETCH_MY_SERVICES_FAILED':
            return { ...state, servicesLoading: action.loading, error: action.error };

        case 'FETCH_ALL_SERVICES_PENDING':
            return { ...state, servicesLoading: action.loading };
        case 'FETCH_ALL_SERVICES_SUCCESS':
            return { ...state, services: action.services, servicesLoading: action.loading };
        case 'FETCH_ALL_SERVICES_FAILED':
            return { ...state, servicesLoading: action.loading, error: action.error };

        case 'SEARCH_SERVICES_PENDING':
            return { ...state, servicesLoading: action.loading };
        case 'SEARCH_SERVICES_SUCCESS':
            return { ...state, services: action.services, servicesLoading: action.loading };
        case 'SEARCH_SERVICES_FAILED':
            return { ...state, servicesLoading: action.loading, error: action.error };

        case 'FETCH_SINGLE_SERVICE_PENDING':
            return { ...state, serviceLoading: action.loading };
        case 'FETCH_SINGLE_SERVICE_SUCCESS':
            return { ...state, service: action.service, serviceLoading: action.loading };
        case 'FETCH_SINGLE_SERVICE_FAILED':
            return { ...state, serviceLoading: action.loading, error: action.error };

        case 'DELETE_SERVICE_PENDING':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_SERVICE_SUCCESS':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_SERVICE_FAILED':
            return { ...state, deleteLoading: action.loading, error: action.error };

        default:
            return state;
    }
};