import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as GigActions from '../../redux/actions/gigs';
import moment from 'moment';
import validator from 'validator';
import Loading from '../../components/Loading';
import ServiceItem from '../../components/ServiceItem';
import { formatter } from '../../utils/formatter';

class InnerGig extends Component {

	componentDidMount() {
		console.log(this.props)
		const { id } = this.props.match.params;
		this.props.dispatch(GigActions.fetchSingleGig(id))
	}

	render() {
		const { gigs: { gig, gigLoading } } = this.props;
		const user = gig && gig.user;
		const userId = gig && gig.user_id;
		const gigId = gig && gig.id;
		const media = `https://backend-vendor-test.elfrique.com/public/${gig && gig.media}`
		const defaultMedia = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fpngimage.net%2Fdefault-image-png-8%2F&psig=AOvVaw1iiS7sSghqoo5I3vGZ6X8W&ust=1582063189476000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOj9qvbK2ecCFQAAAAAdAAAAABAD"
		return gigLoading ? <Loading /> : (
			<React.Fragment>
				<section className="banner-dashtop position-relative align-middle">
					<div className="position-absolute w-100 h-100">
						<div className="container p-md-1">
							<div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
								<div className="banner-top results row m-0  h-100 w-100 align-items-center justify-content-between">
									<div className="authorinfo row h-100 align-item-center col-12 col-md-7">
										<div className="row col-12 px-0 py-3 mx-0 flex-column justify-content-between text-left">
											<h4 className="graytxt my-2 mx-0">Edumare Caterers, Buffet for Weddings, Birthdays, Burials and so on</h4>
											<p className="graytxt h5 font-weight-normal my-2 mx-0">₦ 300,000 - ₦ 2,000,000</p>
											<a href="#" className="text-muted small"><span className="orangetxt p mr-3"><i className="far fa-star"></i></span>Save as Favourite</a>
										</div>
									</div>
									<div className="h-100 col-12 col-md-5 text-center">
										<div className="row flex-column justify-content-between align-items-end w-100 h-100 mb-0 px-0 py-3 mx-0">
											<div className="row flex-column px-0 mx-0">
												<p className="mb-1 text-right pr-5 graytxt"><small>SHARE THIS LISTING</small></p>
												<div className="customsoc my-1 w-100 text-right">
													<a href="#" className="col-3 d-inline-block fbbtn ml-0"><i className="fab fa-facebook-f"></i></a>

													<a href="#" className="col-3 d-inline-block twtbtn ml-2"><i className="fab fa-twitter"></i></a>

													<a href="#" className="col-3 d-inline-block linkbtn ml-2"><i className="fab fa-linkedin"></i></a>

													<a href="#" className="col-3 d-inline-block mailbtn ml-2"><i className="fas fa-envelope"></i></a>
												</div>
												<div className="pr-0 mt-2 text-right">
													<p>
														<a href="#" className="badge sear badge-success">BUFFETS</a>
														<a href="#" className="badge sear badge-success">WEDDINGS</a>
													</p>
												</div>
												<p className="card-text mt-3 text-right">
													<span className="locatr mr-4">
														<small className="text-muted"><small><i className="fas fa-location-arrow"></i></small> Ojodu, Lagos</small>
													</span>
													<span className="postime mr-4">
														<small className="text-muted"><small><i className="far fa-clock"></i></small> Posted 30 mins ago</small>
													</span>
													<span className="postime mr-4">
														<small className="text-muted">Seen 300 times</small>
													</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<article className="top-background result w-100 h-100">
					<div className="container py-3 p-md-3">
						<div className="row">
							<section className="main-body p-0 col-12">
								<div className="banner-after pt-4 col-12 col-lg-9 float-none float-lg-left">
									<div className="row col-12 grid-label justify-content-start p-0 mx-0 my-3 text-left">
										<p className="graytxt">
											<small className="">
												{/* <a href="#" className="orangetxt">Gigs by Location </a>   >
											<a href="#" className="orangetxt">Ojodu </a>  >
											<a href="#" className="orangetxt">Catering and Hospitatlity </a>  >
											<a href="#" className="graytxt">Edumare Caterers</a> */}
											</small>
										</p>
									</div>
									<div className="row m-0 banner-txt justify-content-between h-100 col-12">
										<div className="">
											<div style={{ backgroundImage: 'url(../img/customerdash-img.png)', minHeight: '450px' }} className="img-fluid img rounded"></div>
											<div id="productslide" className="carousel slide mt-3" data-ride="carousel">
												<ol className="carousel-indicators">
													<li data-target="#productslide" data-slide-to="0" className="active"></li>
													<li data-target="#productslide" data-slide-to="1"></li>
													<li data-target="#productslide" data-slide-to="2"></li>
												</ol>
												<div className="carousel-inner">
													<div className="carousel-item active">
														<div className="row px-0 w-100">
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
														</div>
													</div>
													<div className="carousel-item">
														<div className="row px-0 w-100">
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
														</div>
													</div>
													<div className="carousel-item">
														<div className="row px-0 w-100">
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
															<a href="#" target="_blank" className="col-3 px-2 d-inline-block">
																<img src="../img/productslideimg.png" className="img-fluid produc-banner" alt="produc-banner" />
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="bg-white rounded shadow text-left p-5 my-4">
												<h5 className="w-100 graytxt border-bottom py-2 mb-4 mt-2">Service Description</h5>
												<p className="graytxt">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
												<p className="graytxt">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
												<p className="graytxt">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit.</p>
												<h5 className="w-100 graytxt border-bottom py-2 mb-4 mt-2">Other Details</h5>
												<p className="graytxt">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
												<p className="graytxt">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
											</div>
										</div>
										<div className="row w-100 justify-content-between py-3 my-3 border-bottom">
											<div className="grid-title">
												<h5>Similar Ads</h5>
											</div>
										</div>
										<div id="cardgrid" className="grid slide">
											<div className="">
												<div className="grid-item">
													<div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0 pb-4">
														<div className="col-12 grid-card p-0 mb-3">
															<div className="card cardresult shadow w-100">
																<div className="row h-100 no-gutters">
																	<div className="col-md-4 h-100">
																		<div style={{ backgroundImage: 'url(../img/resultimg.png)' }} className="card-img rounded-left h-100"></div>
																	</div>
																	<div className="col-md-8 p-0">
																		<div className="card-body justify-content-between py-2 px-3 p-md-3 h-100">
																			<div className="row m-0 mb-2 mb-md-4 justify-content-between ">
																				<div className="col-12 col-md-6 pl-0 float-none float-md-left">
																					<p className="card-text">
																						<span className="locat">
																							<small className="text-muted"><small><i className="fas fa-location-arrow"></i></small> Ojodu, Lagos</small>
																						</span>
																						<span className="postime ml-2">
																							<small className="text-muted"><small><i className="far fa-clock"></i></small> Posted 30 mins ago</small>
																						</span>
																					</p>
																				</div>
																				<div className="col-12 col-md-6 p-0 float-none float-md-right text-left text-md-right">
																					<p>
																						<a href="#" className="badge sear badge-success">BUFFETS</a>
																						<a href="#" className="badge sear badge-success">WEDDINGS</a>
																					</p>
																				</div>
																			</div>
																			<h5 className="card-title mb-2">Caterers Needed for Wedding in Ojodu</h5>

																			<div className="row m-0 mb-2 mb-md-4 justify-content-start">
																				<div className="mr-3">
																					<p className="card-text">
																						<small className="text-muted">Budget</small>
																					</p>
																					<h6 className="card-text">₦ 300,000 - ₦ 300,000</h6>
																				</div>
																				<div className="">
																					<p className="card-text">
																						<small className="text-muted">Deadline</small>
																					</p>
																					<h6 className="card-text">30th Nov</h6>
																				</div>
																			</div>
																			<div className="row m-0 mt-auto p-0 pt-2 pt-md-3 border-top justify-content-between align-items-center">
																				<a href="inner-product-page-gigs.html" className="button learn-link">Learn More</a>
																				<a href="#" className="text-muted small"><span className="orangetxt p mr-3"><i className="far fa-star"></i></span> Save as Favourite</a>
																				<a href="#" className="authorimg border border-white shadow-lg"><img src="../img/authorimg.png" className="img-fluid" /></a>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12 grid-card p-0 mb-3">
															<div className="card cardresult shadow w-100">
																<div className="row h-100 no-gutters">
																	<div className="col-md-4 h-100">
																		<div style={{ backgroundImage: 'url(../img/resultimg.png)' }} className="card-img rounded-left h-100"></div>
																	</div>
																	<div className="col-md-8 p-0">
																		<div className="card-body justify-content-between py-2 px-3 p-md-3 h-100">
																			<div className="row m-0 mb-2 mb-md-4 justify-content-between ">
																				<div className="col-12 col-md-6 pl-0 float-none float-md-left">
																					<p className="card-text">
																						<span className="locat">
																							<small className="text-muted"><small><i className="fas fa-location-arrow"></i></small> Ojodu, Lagos</small>
																						</span>
																						<span className="postime ml-2">
																							<small className="text-muted"><small><i className="far fa-clock"></i></small> Posted 30 mins ago</small>
																						</span>
																					</p>
																				</div>
																				<div className="col-12 col-md-6 p-0 float-none float-md-right text-left text-md-right">
																					<p>
																						<a href="#" className="badge sear badge-success">BUFFETS</a>
																						<a href="#" className="badge sear badge-success">WEDDINGS</a>
																					</p>
																				</div>
																			</div>
																			<h5 className="card-title mb-2">Caterers Needed for Wedding in Ojodu</h5>

																			<div className="row m-0 mb-2 mb-md-4 justify-content-start">
																				<div className="mr-3">
																					<p className="card-text">
																						<small className="text-muted">Budget</small>
																					</p>
																					<h6 className="card-text">₦ 300,000 - ₦ 300,000</h6>
																				</div>
																				<div className="">
																					<p className="card-text">
																						<small className="text-muted">Deadline</small>
																					</p>
																					<h6 className="card-text">30th Nov</h6>
																				</div>
																			</div>
																			<div className="row m-0 mt-auto p-0 pt-2 pt-md-3 border-top justify-content-between align-items-center">
																				<a href="inner-product-page-gigs.html" className="button learn-link">Learn More</a>
																				<a href="#" className="text-muted small"><span className="orangetxt p mr-3"><i className="far fa-star"></i></span> Save as Favourite</a>
																				<a href="#" className="authorimg border border-white shadow-lg"><img src="../img/authorimg.png" className="img-fluid" /></a>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="banner-form col-12 col-lg-3 float-none float-lg-right pt-4">
									<div className="">
										<div className="top-author py-4 px-3 text-center mb-3 rounded shadow">
											<p className="row mb-2 px-0 mx-0 h5 font-weight-normal align-items-center justify-content-center py-1 graytxt border-bottom">Edumare Caterers <span className="verif ml-1"><img src="../img/verified-icon.png" className="img-fluid ml-2" /></span></p>
											<div className="col-12 rounded-circle">
												<div style={{ backgroundImage: 'url(../img/bigauthorimg.png)' }} className="authorinfo-img img-fluid border border-white rounded-circle m-auto shadow"></div>
											</div>
											<Link disabled={gigLoading} to={`/message/${userId}?new=true&gig=${gigId}`} className="button messbut3 my-2">
												Apply for Gig
											</Link>
											<div className="py-2 border-top">
												<p><span className="orangetxt"><i className="fas fa-star"></i></span><span className="orangetxt"><i className="fas fa-star"></i></span><span className="orangetxt"><i className="fas fa-star"></i></span></p>
												<p className="small">Registered 22 Jul 2019</p>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</article>
			</React.Fragment>
		)
	}
}

export default withRouter(
	connect(state => ({
		gigs: state.gigs
	}))(InnerGig)
);