import React, { Component } from 'react';
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import DashboardLayout from './DashboardLayout';
import Homepage from '../containers/guest/Homepage';

import { Private } from './../utils/AuthHelper';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Pricing from '../containers/guest/Pricing';
import VendorSignup from '../containers/auth/VendorSignup';
import CustomerSignup from '../containers/auth/CustomerSignup';
import Login from '../containers/auth/Login';
import About from '../containers/guest/About';
import FAQ from '../containers/guest/FAQ';
import Gigs from '../containers/guest/Gigs';
import InnerGig from '../containers/guest/InnerGig';

import InnerService from '../containers/guest/InnerService';
import ServiceSearchResults from '../containers/guest/ServiceSearchResults';
import Services from '../containers/guest/Services';
import ViewVendor from '../containers/guest/ViewVendor';
import ManagePlans from '../containers/guest/ManagePlans';
import Contact from '../containers/guest/Contact';
import Testimonials from '../containers/guest/Testimonials';
import FormValidator from "../utils/FormValidator";
import ForgetPassword from '../containers/auth/ForgetPassword';
import ResetPassword from '../containers/auth/ResetPassword';

import * as UserActions from './../redux/actions/users';


class MainLayout extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "email", method: "isEmail", validWhen: true, message: "This must be a valid email." },
            { field: "name", method: "isEmpty", validWhen: false, message: "Full name cannot be empty." }
        ]);

        this.state = {
            subscriber: {
                name: "",
                email: ""
            },
            validation: this.validator.reset(),
            addNewsletterSubscriberLoading: false
        }
    }

    componentDidMount() {
        this.props.dispatch(UserActions.getProfile());
        this.props.dispatch(UserActions.getBusinessProfile());
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const subscriber = this.state.subscriber;
        subscriber[propertyName] = e.target.value;
        this.setState({ subscriber })
    }

    handleAddSubscriber = async(e) => {
        e.preventDefault();
        const { email, name } = this.state.subscriber;
        const payload = { email, name };

        
        const formData = new FormData();
        formData.append('email', email);
        formData.append('name', name)
        
        const validation = this.validator.validate(payload);
        
        if (validation.isValid) {
            this.setState({ addNewsletterSubscriberLoading: true})
            await this.props.dispatch(UserActions.addNewsletterSubscriber(formData));
            this.setState({ validation });
            await this.setState({ addNewsletterSubscriberLoading: false})
        } else {
            this.setState({ validation });
        }

    }

    logout = () => {
        this.props.dispatch(UserActions.logoutUser());
    }

    render() {
        const isAuth = window.localStorage.getItem("accessToken");
        const path = this.props.location.pathname;
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
        const isAdmin = profile && profile.admin_level === 1;
        const { validation, addNewsletterSubscriberLoading } = this.state;
        return (
            <main>
                {path === "/signup/vendor" || path === "/signup/customer" || path === "/login" || path === "/forgot/password" || path === "/reset/password" ? null : !isAuth && <Navbar logout={this.logout} isAuth={isAuth ? true : false} user_type={profile && profile.user_type} isAdmin={isAdmin} />}
                <Switch>
                    <Route path="/" exact component={Homepage} />

                    <Route path="/jobs" exact component={Gigs} />
                    <Route path="/gig/view/:id" exact component={InnerGig} />

                    <Route path="/services" exact component={Services} />
                    <Route path="/service/view/:id" exact component={InnerService} />
                    <Route path="/services/search" exact component={ServiceSearchResults} />
                    <Route path="/services/search/:keyword" exact component={ServiceSearchResults} />

                    <Route path="/vendor/view/:id" exact component={ViewVendor} />

                    <Route path="/pricing" exact component={Pricing} />
                    <Route path="/faq" exact component={FAQ} />
                    <Route path="/about" exact component={About} />
                    <Route path="/contact" exact component={Contact} />

                    <Route path="/login" exact component={Login} />
                    <Route path="/forgot/password" exact component={ForgetPassword} />
                    <Route path="/reset/password" exact component={ResetPassword} />
                    <Route path="/signup/vendor" exact component={VendorSignup} />
                    <Route path="/signup/customer" exact component={CustomerSignup} />

                    <Route path="/pricing" exact component={ManagePlans} />
                    <Route path="/testimonials" exact component={Testimonials} />

                    <Route path="/" component={Private(DashboardLayout)} />
                </Switch>
                {path === "/signup/vendor" || path === "/signup/customer" || path === "/login" || path === "/forgot/password" || path === "/reset/password" || path === "/service/post" || path === "/message" 
                ? null 
                : <Footer handleChange={this.handleChange} 
                    handleAddSubscriber={this.handleAddSubscriber} 
                    loading={addNewsletterSubscriberLoading}
                    validation={validation} />}
            </main>
        );
    }
}

export default withRouter(
    connect(state => ({
        // users: state.users
    }))(MainLayout)
);