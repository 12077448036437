const initialState = {
    user: null,
    business: null,
    users: [],
    error: null,
    loading: false,
    userLoading: false,
    usersLoading: false,
    businessLoading: false,
    updatePersonalProfileLoading: false,
    updateBusinessProfileLoading: false,
    addNewsletterSubscriberLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_PENDING':
            return { ...state, loading: action.loading };
        case 'LOGIN_SUCCESS':
            return { ...state, loading: action.loading };
        case 'LOGIN_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'REGISTER_PENDING':
            return { ...state, loading: action.loading };
        case 'REGISTER_SUCCESS':
            return { ...state, loading: action.loading };
        case 'REGISTER_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'FORGOT_PASSWORD_PENDING':
            return { ...state, loading: action.loading };
        case 'FORGOT_PASSWORD_SUCCESS':
            return { ...state, loading: action.loading };
        case 'FORGOT_PASSWORD_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'RESET_PASSWORD_PENDING':
            return { ...state, loading: action.loading };
        case 'RESET_PASSWORD_SUCCESS':
            return { ...state, loading: action.loading };
        case 'RESET_PASSWORD_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'UPDATE_PERSONAL_PROFILE_PENDING':
            return { ...state, updatePersonalProfileLoading: action.loading };
        case 'UPDATE_PERSONAL_PROFILE_SUCCESS':
            return { ...state, updatePersonalProfileLoading: action.loading };
        case 'UPDATE_PERSONAL_PROFILE_FAILED':
            return { ...state, updatePersonalProfileLoading: action.loading, error: action.error };

        case 'UPDATE_BUSINESS_PROFILE_PENDING':
            return { ...state, updateBusinessProfileLoading: action.loading };
        case 'UPDATE_BUSINESS_PROFILE_SUCCESS':
            return { ...state, updateBusinessProfileLoading: action.loading };
        case 'UPDATE_BUSINESS_PROFILE_FAILED':
            return { ...state, updateBusinessProfileLoading: action.loading, error: action.error };

        case 'FETCH_PERSONAL_PROFILE_PENDING':
            return { ...state, userLoading: action.loading };
        case 'FETCH_PERSONAL_PROFILE_SUCCESS':
            return { ...state, user: action.user, userLoading: action.loading };
        case 'FETCH_PERSONAL_PROFILE_FAILED':
            return { ...state, userLoading: action.loading, error: action.error };

        case 'FETCH_BUSINESS_PROFILE_PENDING':
            return { ...state, businessLoading: action.loading };
        case 'FETCH_BUSINESS_PROFILE_SUCCESS':
            return { ...state, business: action.business, businessLoading: action.loading };
        case 'FETCH_BUSINESS_PROFILE_FAILED':
            return { ...state, businessLoading: action.loading, error: action.error };

        case 'FETCH_ALL_USERS_PENDING':
            return { ...state, usersLoading: action.loading };
        case 'FETCH_ALL_USERS_SUCCESS':
            return { ...state, users: action.users, usersLoading: action.loading };
        case 'FETCH_ALL_USERS_FAILED':
            return { ...state, usersLoading: action.loading, error: action.error };

        case 'ADD_NEWSLETTER_SUBSCRIBER_PENDING':
            return { ...state, addNewsletterSubscriberLoading: action.loading };
        case 'ADD_NEWSLETTER_SUBSCRIBER_SUCCESS':
            return { ...state, addNewsletterSubscriberLoading: action.loading };
        case 'ADD_NEWSLETTER_SUBSCRIBER_FAILED':
            return { ...state, addNewsletterSubscriberLoading: action.loading, error: action.error };

        case 'LOGOUT_USER':
            return { ...state, user: action.user };
        default:
            return state;
    }
};