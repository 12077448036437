import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import FormValidator from "../../utils/FormValidator";
import * as UserActions from '../../redux/actions/users';


class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "token", method: "isEmpty", validWhen: false, message: "Token cannot be empty" },
            { field: "password", method: "isEmpty", validWhen: false, message: "Password cannot be empty" },
            { field: "c_password", method: "isEmpty", validWhen: false, message: "Passwords do not match" }
        ]);

        this.state = {
            user: {
                token: "",
                password: "",
                c_password: ""
            },
            validation: this.validator.reset()
        }
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const user = this.state.user;
        user[propertyName] = e.target.value;
        this.setState({ user })
    }

    resetPassword = (e) => {
        e.preventDefault();
        const { token, password, c_password } = this.state.user;
        const payload = { token, password, c_password };

        const formData = new FormData();
        formData.append('token', token);
        formData.append('password', password);
        formData.append('c_password', c_password);

        const validation = this.validator.validate(payload);

        if (validation.isValid) {
            this.props.dispatch(UserActions.resetPassword(formData));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    render() {
        const { validation } = this.state;
        const { error, loading } = this.props.users;
        return (
            <article style={{ background: '#ffa010', backgroundImages: 'url(../img/loginbackground.png)', minHeight: '100vh' }} className="signup-login w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0 justify-content-center align-items-center" style={{height: '100vh'}}>
                        <section className="main-body p-5s col-md-6">
                            <div className="m-5s">
                                <a href="/" className="navbar-brand pr-4 mb-5 d-block text-center" rel="home" itemprop="url">
                                    <img width="98" height="42" src="../img/elfrique-logo-white.png" className="custom-logo" alt="Elfrique" itemprop="logo" />
                                </a>
                                <div className="the-back p-2s pt-4" style={{paddingBottom: '50px'}}>
                                    <div className="userall">
                                        <h4 className="graytxt text-center pb-3 border-bottom">Reset Password</h4>
                                        <div className="container-fluid">
                                            <div className="row m-0 justify-content-center text-center">
                                                <div className="col-12 p-0 login-wrap">
                                                    <div className="login-form">
                                                        <form className="">
                                                            <div className="form-row">
                                                                <div className="form-group col-12 text-left">
                                                                    <label>Token &nbsp;</label>
                                                                    { validation.token.isValid ? '' : <span className="error-message text-left">{validation.token.message}</span> }
                                                                    <input type="text" onChange={this.handleChange.bind(this, "token")} className="form-control shadows" id="loginControlEmail" placeholder="Token" />
                                                                </div>
                                                                <div className="form-group col-12 text-left">
                                                                    <label>New Password &nbsp;</label>
                                                                    { validation.password.isValid ? '' : <span className="error-message text-left">{validation.password.message}</span> }
                                                                    <input type="password" onChange={this.handleChange.bind(this, "password")} className="form-control shadows" id="loginControlEmail" placeholder="New Password" />
                                                                </div>
                                                                <div className="form-group col-12 text-left">
                                                                    <label>Confirm New Password &nbsp;</label>
                                                                    { validation.c_password.isValid ? '' : <span className="error-message text-left">{validation.c_password.message}</span> }
                                                                    <input type="password" onChange={this.handleChange.bind(this, "c_password")} className="form-control shadows" id="loginControlEmail" placeholder="Confirm New Password" />
                                                                </div>
                                                            </div>
                                                            <button className="btn w-100 shadows" onClick={this.resetPassword}>{ loading ? 'loading...' : 'Reset Password' }</button>
                                                        </form>
                                                        <p className="text-left mt-4">Don't have an account yet?  <Link to="/signup/vendor">Signup as Vendor</Link> or <Link to="/signup/customer">Signup as Customer</Link></p>

                                                        <p className="text-left mt-4">Remeber your password now?  <Link to="/login">Login</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
      users: state.users
    }))(ResetPassword)
);