import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};


export const createService = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'CREATE_SERVICE_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/create-service`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'CREATE_SERVICE_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Service Creation Successful',
                    'You have successfully created a service',
                    'success'
                ).then(() => {
                    window.location.replace('/dashboard');
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'CREATE_SERVICE_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Service Creation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const updateService = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_SERVICE_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/update-service`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'UPDATE_SERVICE_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Service Update Successful',
                    'You have successfully updated this service',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'UPDATE_SERVICE_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Service Update Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const searchServices = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'SEARCH_SERVICES_PENDING',
            loading: true,
            services: null,
            error: null
        })
        return axios.get(`${base_url}/search-services?keyword=${payload.keyword}`, payload, config)
            .then((response) => {
                const { services } = response.data.data;
                dispatch({
                    type: 'SEARCH_SERVICES_SUCCESS',
                    loading: false,
                    services,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'SEARCH_SERVICES_FAILED',
                    loading: false,
                    services: null,
                    error: error.response
                })
            });
    };
};

export const fetchMyServices = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_MY_SERVICES_PENDING',
            loading: true,
            services: null,
            error: null
        })
        return axios.get(`${base_url}/get-authenticated-users-services`, configAuth)
            .then((response) => {
                const services = response.data.data;
                dispatch({
                    type: 'FETCH_MY_SERVICES_SUCCESS',
                    loading: false,
                    services,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_MY_SERVICES_FAILED',
                    loading: false,
                    services: null,
                    error: error.response
                })
            });
    };
};

export const fetchVendorServices = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_MY_SERVICES_PENDING',
            loading: true,
            services: null,
            error: null
        })
        return axios.get(`${base_url}/get-user-services/${id}`, configAuth)
            .then((response) => {
                const services = response.data.data;
                dispatch({
                    type: 'FETCH_MY_SERVICES_SUCCESS',
                    loading: false,
                    services,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_MY_SERVICES_FAILED',
                    loading: false,
                    services: null,
                    error: error.response
                })
            });
    };
};

export const fetchAllServices = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_SERVICES_PENDING',
            loading: true,
            services: null,
            error: null
        })
        return axios.get(`${base_url}/get-all-services`, config)
            .then((response) => {
                const services = response.data.data;
                dispatch({
                    type: 'FETCH_ALL_SERVICES_SUCCESS',
                    loading: false,
                    services,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_SERVICES_FAILED',
                    loading: false,
                    services: null,
                    error: error.response
                })
            });
    };
};

export const fetchSingleService = (service_id) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_SINGLE_SERVICE_PENDING',
            loading: true,
            service: null,
            error: null
        })
        return axios.get(`${base_url}/get-single-service?service_id=${service_id}`, configAuth)
            .then((response) => {
                const service = response.data.data;
                const status = response.data.status;
                if (status === 0) {
                    return Swal.fire(
                        'Service no longer exist',
                        'This service has most likely been deleted',
                        'error'
                    ).then(() => {
                        window.open('/services', '_self');
                    })
                }
                dispatch({
                    type: 'FETCH_SINGLE_SERVICE_SUCCESS',
                    loading: false,
                    service,
                    error: null
                })
            })
            .catch((error) => {
                Swal.fire(
                    'Service could not be displayed',
                    'An error occured, please refresh the page',
                    'error'
                )
                dispatch({
                    type: 'FETCH_SINGLE_SERVICE_FAILED',
                    loading: false,
                    service: null,
                    error: error.response
                })
            });
    };
};

export const deleteService = (service_id) => {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_SERVICE_PENDING',
            loading: true,
            error: null
        })
        return axios.delete(`${base_url}/delete-service/${service_id}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'DELETE_SERVICE_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Service Deletion Successful',
                    'You have successfully deleted this service',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                Swal.fire(
                    'Service Deletion Failed',
                    'An error occured, please try again',
                    'error'
                )
                dispatch({
                    type: 'DELETE_SERVICE_FAILED',
                    loading: false,
                    error: error.response
                })
            });
    };
};
