const initialState = {
    subscriptions: [],
    error: null,
    data: null,
    loading: false,
    subscriptionsLoading: false,
    planActivationError: null,
    planPaymentError: null,
    cancelLoading: false,
    subscriptionData: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PAYMENT_RECORDS_PENDING':
            return { ...state, loading: action.loading };
        case 'GET_PAYMENT_RECORDS_SUCCESS':
            return { ...state, data: action.data, loading: action.loading };
        case 'GET_PAYMENT_RECORDS_FAILED':
            return { ...state, loading: action.loading, error: action.error };

        case 'INITIATE_PLAN_ACTIVATION_PENDING':
            return { ...state, createLoading: action.loading };
        case 'INITIATE_PLAN_ACTIVATION_SUCCESS':
            return { ...state, data: action.data, createLoading: action.loading };
        case 'INITIATE_PLAN_ACTIVATION_FAILED':
            return { ...state, createLoading: action.loading, planActivationError: action.error };

        case 'PLAN_PAYMENT_PENDING':
            return { ...state, updateLoading: action.loading };
        case 'PLAN_PAYMENT_SUCCESS':
            return { ...state, updateLoading: action.loading };
        case 'PLAN_PAYMENT_SUCCESS':
            return { ...state, updateLoading: action.loading, planPaymentError: action.error };

        case 'CANCEL_SUBSCRIPTION_PENDING':
            return { ...state, cancelLoading: action.loading };
        case 'CANCEL_SUBSCRIPTION_SUCCESS':
            return { ...state, cancelLoading: action.loading };
        case 'CANCEL_SUBSCRIPTION_SUCCESS':
            return { ...state, cancelLoading: action.loading, planPaymentError: action.error };

        case 'GET_SUBSCRIPTION_PENDING':
            return { ...state, subscriptionData: null, loading: action.loading };
        case 'GET_SUBSCRIPTION_SUCCESS':
            return { ...state, subscriptionData: action.data, loading: action.loading };
        case 'GET_SUBSCRIPTION_SUCCESS':
            return { ...state, loading: action.loading, planPaymentError: action.error };
            
        default:
            return state;
    }
};