import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormValidator from '../../utils/FormValidator';
import * as GigActions from '../../redux/actions/gigs';


class PostGig extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "name", method: "isEmpty", validWhen: false, message: "name cannot be empty." },
            { field: "description", method: "isEmpty", validWhen: false, message: "description cannot be empty." },
            { field: "category", method: "isEmpty", validWhen: false, message: "category cannot be empty." },
            { field: "budget_start", method: "isEmpty", validWhen: false, message: "budget_start cannot be empty." },
            { field: "budget_end", method: "isEmpty", validWhen: false, message: "budget_end cannot be empty." },
            { field: "duration_start", method: "isEmpty", validWhen: false, message: "duration_start cannot be empty." },
            { field: "duration_end", method: "isEmpty", validWhen: false, message: "duration_end cannot be empty." },
            // { field: "media", method: "isEmpty", validWhen: false, message: "media cannot be empty." },
            { field: "tags", method: "isEmpty", validWhen: false, message: "tags cannot be empty." }
        ]);

        this.state = {
            gig: {
                name: "",
                description: "",
                category: "",
                budget_start: "",
                budget_end: "",
                timeline: "",
                media: "", 
                tags: []
            },
            validation: this.validator.reset()
        }
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const gig = this.state.gig;
        gig[propertyName] = e.target.value;
        this.setState({ gig })
    }

    handleImageChange = (e) => {
        e.preventDefault();
        const gig = this.state.gig;
        const media = e.target.files[0];
        gig["media"] = media;
        this.setState({ gig })
    }

    createGig = (e) => {
        e.preventDefault();
        const gig = this.state.gig;

        const payload = new FormData();
        Object.keys(gig).forEach((key) => {
            payload.append(key, gig[key])
        })

        const validation = this.validator.validate(gig);

        if (validation.isValid) {
            this.props.dispatch(GigActions.createGig(payload));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    render() {
        const { validation } = this.state;
        const { error, loading } = this.props.gigs;
        return (
            <article style={{ backgroundImage: 'url(img/loginbackground.png)' }} className="signup-login listin w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5s col-12">
                            <div className="">
                                <div className="the-back p-5">

                                    <div className="userall">
                                        <div className="border-bottoms">
                                            <h2 className="graytxt text-center pb-3">Post a Gig Listing</h2>
                                        </div>
                                        <div className="container-fluid">
                                            <div className="signup-form row m-0 justify-content-center">
                                                <div className="col-md-8 p-0 login-wrap">
                                                    <div className="profile-form">
                                                        <form className="w-100 mx-md-3 px-md-2">
                                                            <div className="form-row py-3 border-bottom">
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label for="ControlListingName">Name of Listing</label>
                                                                    { validation.name.isValid ? '' : <span className="error-message text-left"> {validation.name.message}</span> }
                                                                    <input type="text" className="form-control" onChange={this.handleChange.bind(this, "name")} id="ControlListingName" placeholder="e.g Supply 50 Cartons of Indomie Noodles Needed within Ikeja Free Delivery" />
                                                                </div>
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label for="ControlListingName">Listing Description</label>
                                                                    { validation.description.isValid ? '' : <span className="error-message text-left"> {validation.description.message}</span> }
                                                                    <textarea className="form-control" onChange={this.handleChange.bind(this, "description")} id="Controldescrip" aria-label="With textarea" placeholder="Description of Gig"></textarea>
                                                                </div>
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label for="Controlcatgrp">Categories & Grouping</label>
                                                                    <div className="form-row mx-0 my-0">
                                                                        <div className="col-12">
                                                                            { validation.category.isValid ? '' : <span className="error-message text-left"> {validation.category.message}</span> }
                                                                            <select id="Controlcatgrp" className="form-control" onChange={this.handleChange.bind(this, "category")}>
                                                                                <option selected>Select Listing Category</option>
                                                                                <option>Lagos</option>
                                                                                <option>Oyo</option>
                                                                                <option>Abuja</option>
                                                                                <option>Kano</option>
                                                                                <option>Rivers</option>
                                                                                <option>Delta</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label for="Controlbdgt">Budget Range</label>
                                                                    <div className="form-row mx-0 my-0">
                                                                        <div className="col-12 col-md-6">
                                                                            { validation.budget_start.isValid ? '' : <span className="error-message text-left"> {validation.budget_start.message}</span> }
                                                                            <input type="number" id="Controlbdgt" name="strtbdgt" className="form-control" onChange={this.handleChange.bind(this, "budget_start")} placeholder="N0.00" />
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            { validation.budget_end.isValid ? '' : <span className="error-message text-left"> {validation.budget_end.message}</span> }
                                                                            <input type="number" id="Controlbdgt" name="endbdgt" className="form-control" onChange={this.handleChange.bind(this, "budget_end")} placeholder="N0.00" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label for="Controltmdl">Timeline/Deadline</label>
                                                                    <div className="form-row mx-0 my-0">
                                                                        <div className="col-12">
                                                                            { validation.timeline.isValid ? '' : <span className="error-message text-left"> {validation.timeline.message}</span> }
                                                                            <input type="date" id="Controltmdl" name="strtline" className="form-control" onChange={this.handleChange.bind(this, "timeline")} placeholder="13 - 10 - 2019" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-row py-3 border-bottom">
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label>Upload Media, Licenses, Brochure & Pictures</label>
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" onChange={this.handleImageChange} accept="image/x-png,image/gif,image/jpeg,image/jpg" id="customFile" />
                                                                        <label className="custom-file-label" for="customFile">Choose file</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-row py-3">
                                                                <div className="form-group col-12 text-left"> 
                                                                    <label for="Controltag">Tags</label>
                                                                    { validation.tags.isValid ? '' : <span className="error-message text-left"> {validation.tags.message}</span> }
                                                                    <textarea className="form-control" onChange={this.handleChange.bind(this, "tags")} id="Controltag" aria-label="With textarea" placeholder="Make your listing more discoverable"></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="form-group col-12 col-md-6 text-left"> 
                                                                </div>
                                                                <div className="form-group col-12 col-md-5 text-center text-md-right">
                                                                    <button className="btn w-100 shadow" onClick={this.createGig}> { loading ? 'Loading...' : 'Post Job'}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
      gigs: state.gigs
    }))(PostGig)
);